import { useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { useLocationViewport } from 'app/shared/hooks/useLocationViewport';
import { LocationPermissionDialog } from 'app/shared/components/location-permission-dialog';
import Roads, { RoutingSegments } from 'app/modules/map/layers/roads';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import MapGL, { MapboxEvent, NavigationControl, ViewState, LngLat, MapboxGeoJSONFeature, Layer, MapRef } from 'react-map-gl';
import { Feature } from 'geojson';
import bbox from '@turf/bbox';

export interface IMapWithRoadsProps {
  interactiveLayerIds: string[];
  onClick: (feature: MapboxGeoJSONFeature, latLng: LngLat) => void;
}

export const MapWithRoads = (props: PropsWithChildren<IMapWithRoadsProps>) => {
  const [cursor, setCursor] = useState<string>('auto');
  const [mapLoaded, setMapLoaded] = useState(false);
  const tileUrl = useAppSelector(state => state.applicationProfile.tilesEndpoint);
  const prayerWalkEvent = useCurrentEvent();
  const [minLng, minLat, maxLng, maxLat] = bbox(prayerWalkEvent.boundary);

  const defaultViewport = {
    bounds: [minLng, minLat, maxLng, maxLat] as [number, number, number, number],
    fitBoundsOptions: { padding: 5, minZoom: 8 },
  };

  const { isLocationDialogOpen, onLocationDialogClose, onLetMeChoose } = useLocationViewport({
    boundary: prayerWalkEvent.boundary,
    defaultBounds: [minLng, minLat, maxLng, maxLat],
  });

  const onClick = useCallback(
    event => {
      // TODO the first feature may not always be the one we want
      const feature = event.features && event.features[0];
      if (feature) {
        props.onClick(
          {
            ...feature,
            geometry: feature.geometry,
            id: feature.properties['id'] && feature.properties['id'].toString(),
          },
          event.lngLat
        );
      }
    },
    [props.onClick]
  );

  const onMapLoaded = useCallback((event: MapboxEvent) => {
    setMapLoaded(true);
  }, []);

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  return (
    <>
      <MapGL
        id="roadsMap"
        initialViewState={defaultViewport}
        style={{
          width: '100%',
          height: '100%',
          // https://github.com/philipwalton/flexbugs/issues/197 means the below has to be absolute
          // position: 'absolute',
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        mapStyle="mapbox://styles/philhardwick/cli74ohpg00c301r13k2m6kwh"
        clickTolerance={4}
        onClick={onClick}
        cursor={cursor}
        interactiveLayerIds={mapLoaded ? props.interactiveLayerIds : []}
        onLoad={onMapLoaded}
        mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
        ref={el => ((window as any).map = el)}
        logoPosition="top-left"
      >
        {props.children}
        <Roads tileUrl={`${tileUrl}/public.roads_in_prayer_walk_event/{z}/{x}/{y}.pbf?prayer_walk_event_id=${prayerWalkEvent.id}`} />
        {['2', '3', '4', '5'].map(name => {
          return <Layer id={'z' + name} key={'z' + name} type="background" layout={{ visibility: 'none' }} paint={{}} />;
        })}
        {/* Routing segments is the layer that gets selected, but isn't shown on the map since it has none of the attributes needed for changing the style  */}
        <RoutingSegments
          tileUrl={`${tileUrl}/public.routing_segments_in_prayer_walk_event/{z}/{x}/{y}.pbf?prayer_walk_event_id=${prayerWalkEvent.id}`}
        />
        <NavigationControl showCompass={false} position="top-left" />
      </MapGL>

      {prayerWalkEvent.settings?.features?.locationBasedViewportEnabled && (
        <LocationPermissionDialog isOpen={isLocationDialogOpen} onClose={onLocationDialogClose} onLetMeChoose={onLetMeChoose} />
      )}
    </>
  );
};
