import React, { useEffect } from 'react';
import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntityForCurrentUser, updateEntity, reset } from 'app/entities/user-profile/user-profile.reducer';
import { SegmentationFields } from 'app/shared/components/segmentation-fields';
import { hideNav, showNav } from 'app/shared/reducers/app-layout';
import { Navigate } from 'react-router-dom';
import { defaultValue } from 'app/shared/model/user-profile.model';

export const CompleteUserProfileForPrayerWalkEvent = () => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(state => state.userProfile.entity);
  const updateSuccess = useAppSelector(state => state.userProfile.updateSuccess);
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  const segmentationFields = prayerWalkEvent?.settings?.userSegmentation?.fields || [];

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      extraAttributes: userProfile.extraAttributes || {},
    },
  });

  useEffect(() => {
    dispatch(hideNav());
    dispatch(getEntityForCurrentUser());
    return () => {
      dispatch(showNav());
      dispatch(reset());
    };
  }, []);

  // Check if all required fields are already filled in
  const hasAllRequiredFields = () => {
    const requiredFields = segmentationFields.filter(field => field.required).map(field => field.id);

    return requiredFields.every(fieldId => {
      const value = userProfile.extraAttributes?.[fieldId];
      return value && value.trim() !== '';
    });
  };

  // Redirect if update was successful or if all required fields are already present
  if (updateSuccess || (userProfile !== defaultValue && hasAllRequiredFields())) {
    return <Navigate to="/map" />;
  }

  const onSubmit = handleSubmit(values => {
    const updatedUserProfile = {
      ...userProfile,
      extraAttributes: {
        ...userProfile.extraAttributes,
        ...values.extraAttributes,
      },
    };
    dispatch(updateEntity(updatedUserProfile));
  });

  return (
    <Flex direction="column" grow={1}>
      <Box p={[2, 4]}>
        <Box rounded="lg" bg="white" maxW="3xl" width="full" mx="auto" shadow="base" px={[4, 8]} py={4} id="church_confirmation">
          <form onSubmit={onSubmit}>
            <VStack spacing={6}>
              <Box id="previous_church_statement" w="full" fontSize="lg" fontWeight="semibold">
                We need to know a few more things about you.
              </Box>
              <SegmentationFields fields={segmentationFields} register={register} errors={errors} />
              <Button type="submit" width="full" size="lg" colorScheme="primary" isLoading={isSubmitting}>
                Save and Continue
              </Button>
            </VStack>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default CompleteUserProfileForPrayerWalkEvent;
