import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { IRoad } from 'app/shared/model/road.model';
import { Feature, Polygon } from 'geojson';
import { IOrganisation } from 'app/shared/model/organisation.model';

export interface IPrayerWalkEvent {
  id?: number;
  name?: string;
  subdomain?: string;
  geoArea?: string;
  logoUrl?: string;
  mapboxStyleId?: string;
  mapboxTilesetId?: string;
  settings?: IPrayerWalkEventSettings;
  enabled?: boolean;
  boundary?: Feature<Polygon>;
  prayerRoutes?: IPrayerRoute[];
  roads?: IRoad[];
  organisation?: IOrganisation | null;
}

export interface IColourStop {
  colour?: string;
  colourName?: string;
  decimalPercentage?: number;
}

export interface IMapStyle {
  colourStops: IColourStop[];
  heatmapLineColourIsRelative: boolean;
  numOfWalksRequiredForHighestColour: number;
}

export interface IPrayerWalkEventSettings {
  initialViewport?: ISettingsViewport;
  colours?: ISettingsColours;
  logos?: ISettingsLogos;
  links?: ISettingsLinks;
  content?: ISettingsContent;
  domains?: string[];
  features?: IFeatures;
  mapStyle?: IMapStyle;
  creationState?: ICreationState;
  mapData?: IMapData;
  userSegmentation?: IUserSegmentation;
}

export interface IMapData {
  totalKmsOfRoadsInBoundary?: number;
}

export interface IUserSegmentation {
  enabled?: boolean;
  fields?: ISegmentationField[];
}

export interface ISegmentationField {
  id?: string;
  label?: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  helpText?: string;
  options?: IFieldOption[];
}

export interface IFieldOption {
  value?: string;
  label?: string;
}

export interface ISettingsViewport {
  longitude: number;
  latitude: number;
  zoom: number;
}

export interface IFeatures {
  socialLoginEnabled: boolean;
  editableBoundaryEnabled: boolean;
  heatmapEnabled: boolean;
  participantsListEnabled: boolean;
  prayerNeedsEnabled: boolean;
  prayerEventChallengesEnabled: boolean;
  featureTourEnabled: boolean;
  locationBasedViewportEnabled: boolean;
}

export interface ISettingsColours {
  earmarkedColour?: ISettingsColour;
  completedColour?: ISettingsColour;
  heatmapLowColour?: ISettingsColour;
  heatmapHighColour?: ISettingsColour;
  branding?: ISettingsBranding;
}

export interface ISettingsColour {
  name: string;
  code: string;
}

export interface ISettingsBranding {
  primary?: string;
  textPrimary?: string;
  secondary?: string;
  textSecondary?: string;
  light?: string;
  dark?: string;
}

export interface ISettingsLogos {
  settingsLogoUrl?: string;
  faviconFolderUrl?: string;
  faviconPreviewUrl?: string;
  colour?: string;
  backgroundColour?: string;
}

export interface ISettingsLinks {
  privacyPolicy?: string;
  contactUs?: string;
  externalLinks?: Array<IExternalLink>;
}

export interface IExternalLink {
  name?: string;
  icon?: IconProp;
  url?: string;
}

export interface IHomeScreenBackground {
  type?: 'VIDEO' | 'IMAGE';
  url?: string;
}

export interface ISettingsContent {
  preHowDoIGetStartedMarkdown?: string;
  postHowDoIGetStartedMarkdown?: string;
  postConfirmingYourRouteIsCompletedMarkdown?: string;
  disabledMessage?: string;
  homeScreenBackground?: IHomeScreenBackground;
}

export interface ICreationState {
  detailsFormCompleted: boolean;
  brandingFormCompleted: boolean;
  logoFormCompleted: boolean;
  mapFormCompleted: boolean;
  launchFormCompleted: boolean;
  featuresFormCompleted: boolean;
}

export const defaultValue: Readonly<IPrayerWalkEvent> = {
  enabled: true,
};
