import { useState, useCallback, useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { Feature, Polygon } from 'geojson';
import { useMap } from 'react-map-gl';

interface LocationViewport {
  longitude?: number;
  latitude?: number;
  zoom?: number;
  bounds?: [number, number, number, number];
  fitBoundsOptions?: { padding: number; minZoom: number };
}

interface UseLocationViewportProps {
  boundary: Feature<Polygon>;
  defaultBounds: [number, number, number, number];
}

export const useLocationViewport = ({ boundary, defaultBounds }: UseLocationViewportProps) => {
  const [locationPermission, setLocationPermission] = useState<'granted' | 'denied' | 'prompt' | undefined>();
  const [userLocationRequested, setUserLocationRequested] = useState<{ lng: number; lat: number } | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { roadsMap: map } = useMap();

  useEffect(() => {
    // Check if the Permissions API is supported
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        setLocationPermission(permissionStatus.state);

        // Listen for changes in permission state
        permissionStatus.onchange = () => {
          setLocationPermission(permissionStatus.state);
        };
      });
    }
  }, []);

  useEffect(() => {
    if (locationPermission === 'prompt') {
      onOpen();
    } else {
      onClose();
    }
  }, [locationPermission, onOpen, onClose]);

  const requestLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { longitude, latitude } = position.coords;
          // Check if location is within boundary
          const isWithinBoundary = booleanPointInPolygon([longitude, latitude], boundary);

          if (isWithinBoundary) {
            setUserLocationRequested({ lng: longitude, lat: latitude });
            if (map) {
              map.flyTo({
                center: [longitude, latitude],
                zoom: 14,
              });
            }
          }
          setLocationPermission('granted');
        },
        error => {
          console.error('Error getting location:', error);
          setLocationPermission('denied');
        }
      );
    }
  }, [boundary, map]);

  useEffect(() => {
    if (locationPermission === 'granted' && !userLocationRequested) {
      requestLocation();
    }
  }, [locationPermission, userLocationRequested, requestLocation]);

  return {
    locationPermission,
    isLocationDialogOpen: isOpen,
    onLocationDialogClose: onClose,
    onLetMeChoose() {
      requestLocation();
      onClose();
    },
  };
};
