import { IUser } from 'app/shared/model/user.model';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { IChurch } from 'app/shared/model/church.model';

export interface IUserProfile {
  id?: number;
  church?: string | null;
  consentToContactAboutPrayerWalkEvent?: boolean | null;
  consentToContactFromOrganisation?: boolean | null;
  consentToProvidingAnonymousAnalytics?: boolean | null;
  publiclyViewable?: boolean | null;
  user?: IUser | null;
  eventSignedUpFor?: IPrayerWalkEvent | null;
  verifiedChurch?: IChurch | null;
  extraAttributes?: Record<string, string>;
}

export const defaultValue: Readonly<IUserProfile> = {
  consentToContactAboutPrayerWalkEvent: true,
  consentToContactFromOrganisation: false,
  consentToProvidingAnonymousAnalytics: false,
  publiclyViewable: true,
};
