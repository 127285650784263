import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { ISegmentationField } from 'app/shared/model/prayer-walk-event.model';
import { FormCheckbox, FormField } from './form-field';
import { FormSelect } from './form-field';
import { UseFormRegister, FieldErrors } from 'react-hook-form';

interface SegmentationFieldsProps {
  fields: ISegmentationField[];
  register: UseFormRegister<any>;
  errors: FieldErrors;
}

export const renderSegmentationField = (field: ISegmentationField, register: UseFormRegister<any>, errors: FieldErrors) => {
  switch (field.type) {
    case 'text':
      return (
        <FormField
          key={field.id}
          label={field.label}
          errors={errors}
          isRequired={field.required}
          placeholder={field.placeholder}
          helperText={field.helpText}
          {...register(`extraAttributes.${field.id}`, {
            required: field.required ? `${field.label} is required.` : false,
          })}
        />
      );
    case 'select':
      return (
        <FormSelect
          key={field.id}
          label={field.label}
          errors={errors}
          isRequired={field.required}
          helperText={field.helpText}
          placeholder={field.placeholder}
          {...register(`extraAttributes.${field.id}`, {
            required: field.required ? `${field.label} is required.` : false,
          })}
        >
          {field.options?.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </FormSelect>
      );
    case 'checkbox':
      return (
        <FormCheckbox
          key={field.id}
          errors={errors}
          helperText={field.helpText}
          {...register(`extraAttributes.${field.id}`, {
            required: field.required ? `${field.label} is required.` : false,
          })}
        >
          {field.label}
        </FormCheckbox>
      );
    case 'radio':
      return (
        <Box key={field.id} mb={4}>
          <FormField as="fieldset" label={field.label} errors={errors} isRequired={field.required} helperText={field.helpText}>
            <Stack spacing={2} mt={2}>
              {field.options?.map(option => (
                <FormCheckbox
                  key={option.value}
                  type="radio"
                  errors={errors}
                  {...register(`extraAttributes.${field.id}`, {
                    required: field.required ? `${field.label} is required.` : false,
                  })}
                  value={option.value}
                >
                  {option.label}
                </FormCheckbox>
              ))}
            </Stack>
          </FormField>
        </Box>
      );
    default:
      return null;
  }
};

export const SegmentationFields = ({ fields, register, errors }: SegmentationFieldsProps) => {
  return <>{fields.map(field => renderSegmentationField(field, register, errors))}</>;
};
