import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

interface LocationPermissionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onLetMeChoose: () => void;
}

export const LocationPermissionDialog = ({ isOpen, onClose, onLetMeChoose }: LocationPermissionDialogProps) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Zoom into your location?
          </AlertDialogHeader>

          <AlertDialogBody>
            We&apos;d like to use your location to show you the most relevant area of the prayer walk. Your location will only be used to
            center the map and won&apos;t be stored.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme="primary" ref={cancelRef} onClick={onLetMeChoose}>
              Let me choose
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
