import React, { useEffect, PropsWithChildren } from 'react';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Navigate } from 'react-router-dom';
import { defaultValue } from 'app/shared/model/user-profile.model';
import { getEntityForCurrentUser, reset } from 'app/entities/user-profile/user-profile.reducer';
import { useFeatureFlag } from 'app/config/feature-flags';

// Is this wrapping the component stack inefficient? It only needs to be checked once
const AccountChecks = (props: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.userProfile.loading);
  const userProfile = useAppSelector(state => state.userProfile.entity);
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  const { children } = props;
  const googleMapsSearchChurchEnabled = useFeatureFlag('googleMapsSearchChurchEnabled');

  useEffect(() => {
    dispatch(getEntityForCurrentUser());
    return () => {
      dispatch(reset());
    };
  }, []);

  // Check if user segmentation is enabled and has required fields
  const userSegmentationEnabled =
    prayerWalkEvent?.settings?.userSegmentation?.enabled && prayerWalkEvent?.settings?.userSegmentation?.fields?.length > 0;

  if (userSegmentationEnabled && userProfile !== defaultValue) {
    const requiredFields = prayerWalkEvent.settings.userSegmentation.fields.filter(field => field.required).map(field => field.id);

    const missingRequiredFields = requiredFields.filter(fieldId => {
      const value = userProfile.extraAttributes?.[fieldId];
      return !value || value.trim() === '';
    });

    if (missingRequiredFields.length > 0) {
      return <Navigate to="/account/complete-user-profile" />;
    }
  }

  if (
    googleMapsSearchChurchEnabled &&
    userProfile !== defaultValue &&
    userProfile !== '' &&
    userProfile.church &&
    !userProfile.verifiedChurch
  ) {
    return <Navigate to="/account/confirm-church" />;
  }

  if (!loading && userProfile !== defaultValue && userProfile !== '' && (userProfile.verifiedChurch || !userProfile.church)) {
    // allow user to continue
    return <>{children}</>;
  } else {
    if (loading) {
      return <LoadingSpinner />;
    } else {
      return <>{children}</>;
    }
  }
};

export default AccountChecks;
