import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route-2';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import { PrayerWalkingRouter } from 'app/shared/route/PrayerWalkingRouter';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import OrganisationLogin from 'app/modules/organisation/initial/initial-page';
import { ForgottenPasswordFinishForm, ForgottenPasswordForm } from 'app/modules/account/password-reset/password-reset-init';
import { RegisterForm, RegistrationSuccess } from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { OrganisationLoginForm, OrganisationLoginOrCreateAccount } from 'app/modules/organisation/initial/login-form';
import { OrganisationCreateAccountForm } from 'app/modules/organisation/onboard/create-account';
import { OrganisationOnboard } from 'app/modules/organisation/onboard/onboard';
import { CreateOrganisationForm } from 'app/modules/organisation/onboard/create-organisation';
import { ChooseSubscription } from 'app/modules/organisation/onboard/choose-subscription';
import OrganisationOnboardChecks from 'app/modules/organisation/onboard/organisation-onboard-checks';
import { OrganisationMainLayout } from 'app/modules/organisation/main/main-layout';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession, onSuccessLoginWithOAuth2 } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { ToastContainer, toast } from 'react-toastify';
import { OrganisationPrayerWalkEvents } from './main/prayer-walk-events';
import {
  PrayerWalkEventUpdate,
  PrayerWalkEventUpdateStepFiveLaunch,
  PrayerWalkEventUpdateStepThreeMap,
  PrayerWalkEventUpdateStepNavigator,
  PrayerWalkEventUpdateStepOneDetails,
  PrayerWalkEventUpdateStepFourFeatures,
  PrayerWalkEventUpdateStepTwoBrand,
  PrayerWalkEventUpdateStepAdvanced,
} from 'app/modules/organisation/main/prayer-walk-event-update';
import { OrganisationPrayerWalkEventOverview } from 'app/modules/organisation/main/prayer-walk-event-overview';
import { OrganisationUserPermissions } from 'app/modules/organisation/main/organisation-user-permissions';
import { OrganisationUserPermissionUpdate } from 'app/modules/organisation/main/organisation-user-permission-update';
import { OrganisationMainIndex } from 'app/modules/organisation/main/main-index';
import { SubscriptionSuccess } from 'app/modules/organisation/onboard/subscription-success';
import { ManagementFavicon } from 'app/modules/organisation/management-favicon';
import { RenewSubscription } from 'app/modules/organisation/main/renew-subscription';
import { BillingRedirect } from './main/billing-redirect';

export const AppRoutesForOrganisation = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
  }, []);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <ChakraProvider
      resetCSS={true}
      theme={extendTheme({
        colors: {
          primary: {
            50: '#93D4FF',
            100: '#7FC0FF',
            200: '#6BACFF',
            300: '#5798FF',
            400: '#4384FF',
            500: '#2f70ff',
            600: '#2566F5',
            700: '#1B5CEB',
            800: '#1152E1',
            900: '#0f42b0',
          },
        },
      })}
    >
      <ManagementFavicon />
      <PrayerWalkingRouter>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <div
          id="app-view-container"
          className="flex-grow-1 d-flex contain"
          style={{
            overflowY: 'scroll',
            minHeight: '0',
          }}
        >
          <ErrorBoundaryRoutes>
            <Route
              path="/organisation"
              element={
                <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
                  <OrganisationOnboardChecks>
                    <OrganisationMainLayout />
                  </OrganisationOnboardChecks>
                </PrivateRoute>
              }
            >
              <Route index element={<OrganisationMainIndex />} />
              <Route path="renew-subscription" element={<RenewSubscription />} />
              <Route path="billing" element={<BillingRedirect />} />
              <Route path="prayer-walk-events" element={<OrganisationPrayerWalkEvents />} />
              <Route path="prayer-walk-events/:id" element={<OrganisationPrayerWalkEventOverview />} />
              <Route path="prayer-walk-events/:id/edit" element={<PrayerWalkEventUpdate />}>
                <Route index element={<PrayerWalkEventUpdateStepNavigator />} />
                <Route path="details" element={<PrayerWalkEventUpdateStepOneDetails />} />
                <Route path="brand" element={<PrayerWalkEventUpdateStepTwoBrand />} />
                <Route path="map" element={<PrayerWalkEventUpdateStepThreeMap />} />
                <Route path="features" element={<PrayerWalkEventUpdateStepFourFeatures />} />
                <Route path="launch" element={<PrayerWalkEventUpdateStepFiveLaunch />} />
                <Route path="advanced" element={<PrayerWalkEventUpdateStepAdvanced />} />
              </Route>
              <Route path="prayer-walk-events/new" element={<PrayerWalkEventUpdate />}>
                <Route index element={<PrayerWalkEventUpdateStepNavigator />} />
                <Route path="details" element={<PrayerWalkEventUpdateStepOneDetails />} />
              </Route>
              <Route path="user-permissions" element={<OrganisationUserPermissions />} />
              <Route path="user-permissions/new" element={<OrganisationUserPermissionUpdate />} />
              <Route path="user-permissions/:id/edit" element={<OrganisationUserPermissionUpdate />} />
              <Route path="logout" element={<Logout />} />
            </Route>
            <Route path="/organisation-onboard" element={<OrganisationOnboard />}>
              <Route path="create-account" element={<OrganisationCreateAccountForm />} />
              <Route path="logout" element={<Logout />} />
              <Route
                path="organisation-details"
                element={
                  <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
                    <CreateOrganisationForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="choose-subscription"
                element={
                  <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
                    <ChooseSubscription />
                  </PrivateRoute>
                }
              />
              <Route
                path="subscription-success"
                element={
                  <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
                    <SubscriptionSuccess />
                  </PrivateRoute>
                }
              />
              <Route path="create-account-success" element={<RegistrationSuccess />} />
            </Route>
            {/* login should redirect to /organisation and it'll continue onboarding if needed */}
            <Route path="/" element={<OrganisationLogin />}>
              <Route path="/forgotten-password/finish" element={<ForgottenPasswordFinishForm />} />
              <Route path="/forgotten-password" element={<ForgottenPasswordForm />} />
              <Route path="/account-activate" element={<Activate loginUrl="/login" />} />
              <Route path="/login" element={<OrganisationLoginForm />} />
              <Route path="/registration-success" element={<RegistrationSuccess />} />
              <Route index element={<OrganisationLoginOrCreateAccount />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </ErrorBoundaryRoutes>
        </div>
      </PrayerWalkingRouter>
    </ChakraProvider>
  );
};

export default AppRoutesForOrganisation;
